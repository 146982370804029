
import { reactive } from "vue";

type RecorderState = {
    isRecording: Boolean;
}

class Recorder {
    stream?: MediaStream;
    recorder?: MediaRecorder;
    chunks: Array<BlobEvent["data"]> = [];

    state: RecorderState = {
        isRecording: false,
    };

    stopPromise?: Promise<void> | null;

    constructor() {
        // nothing here
    }

    async start() {
        await new Promise<void>(async (resolveStart, rejectStart) => {
            this.stopPromise = new Promise<void>(async (resolveStop, rejectStop) => {
                this.stream = await navigator.mediaDevices.getUserMedia({ audio: true });
                this.recorder = new MediaRecorder(this.stream);

                this.recorder.onstart = () => {
                    this.state.isRecording = true;
                    this.chunks.length = 0;
                    resolveStart();
                };

                this.recorder.ondataavailable = (e: BlobEvent) => {
                    this.chunks.push(e.data);
                };

                this.recorder.onstop = () => {
                    this.state.isRecording = false;
                    resolveStop();
                }

                this.recorder.onerror = () => {
                    rejectStart();
                    rejectStop();
                };

                this.recorder.start();
            });
        });
    }

    async stop() {
        if (this.recorder) {
            this.recorder.stop();
        }
        if (this.stream) {
            this.stream.getTracks().forEach((track: MediaStreamTrack) => track.stop());
        }
        if (this.stopPromise) {
            await this.stopPromise;
            this.stopPromise = null;
        }
    }

    getBlob() {
        return new Blob(this.chunks, { type: "audio/ogg; codecs=opus" });
    }
}

export default function createRecorder() {
    return reactive(new Recorder());
}
